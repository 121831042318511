import { useState } from "react";
import { PrismicRichText } from "@prismicio/react";
import { asLink } from "@prismicio/helpers";

import usePrismic from "../../../hooks/usePrismic/usePrismic";

import Modal from "../../feedback/Modal/Modal";
import ModalBody from "../../feedback/Modal/ModalBody";
import ModalFooter from "../../feedback/Modal/ModalFooter";
import ModalHeader from "../../feedback/Modal/ModalHeader";
import ModalTitle from "../../feedback/Modal/ModalTitle";
import Button from "../../inputs/Button/Button";

const CookieConsent = () => {
    const [, forceRender] = useState(0);
    const [document, { state: prismicState }] = usePrismic("bbriui");

    const onAccept = () => {
        localStorage.setItem("buildwise-cookie-consent", "1");
        forceRender((x) => x + 1);
    };

    const accepted = localStorage.getItem("buildwise-cookie-consent");

    return prismicState === "loaded" ? (
        <Modal open={!Boolean(accepted)} backdrop>
            <ModalHeader>
                <ModalTitle>{document!.data.cookie_consent_title}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <PrismicRichText field={document!.data.cookie_consent_text} linkResolver={() => "/"} />
            </ModalBody>
            <ModalFooter>
                <Button
                    id="cookie-consent-more-info-button"
                    variant="secondary"
                    onClick={() =>
                        window.open(asLink(document!.data.cookie_consent_button_more_info_url) ?? "", "_blank")
                    }
                >
                    {document!.data.cookie_consent_button_more_info_text}
                </Button>
                <Button id="cookie-consent-accept-button" variant="primary" onClick={() => onAccept()}>
                    {document!.data.cookie_consent_button_accept_text}
                </Button>
            </ModalFooter>
        </Modal>
    ) : null;
};

export default CookieConsent;
