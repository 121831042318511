import React, { ReactNode, useContext, useEffect, useLayoutEffect, useRef } from "react";
import { AuthenticationResult, InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { BrowserConstants } from "@azure/msal-browser/dist/utils/BrowserConstants";

import { BuildwiseContext } from "../BuildwiseProvider/BuildwiseContext";
import { authenticate } from "../BuildwiseProvider/BuildwiseReducer";
import Spinner from "../../feedback/Spinner/Spinner";

type IdTokenClaims = {
    sub: string;
    display_name: string;
    email: string;
    wtcb_authorization: string;
};

type WtcbAuthorization = {
    subscriberId: string;
    accountId: string;
    roles: WtcbRole[];
};

type WtcbRole = {
    bbri_name: string;
    bbri_code: number;
    bbri_serviceid: string;
};

export interface BuildwiseAuthenticationHandlerProps {
    children: ReactNode;
}

const BuildwiseAuthenticationHandler = ({ children }: BuildwiseAuthenticationHandlerProps) => {
    const { state, dispatch } = useContext(BuildwiseContext);
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const originalFetch = useRef(window.fetch);

    useLayoutEffect(() => {
        BrowserConstants.POPUP_WIDTH = 600;
        BrowserConstants.POPUP_HEIGHT = 850;
    }, []);

    useLayoutEffect(() => {
        if (!isAuthenticated) {
            window.fetch = originalFetch.current;
            return;
        }

        window.fetch = async function (input: RequestInfo | URL, info?: RequestInit) {
            // Don't intercept request regarding authentication or prismic
            // TODO: Add configuration option to skip injecting tokens into custom 3rd party endpoints
            if (input.toString().startsWith(state.instance!.authority) || input.toString().indexOf("prismic.io") >= 0) {
                return originalFetch.current.apply(this, [input, info]);
            }

            const token = await getAccessToken();
            if (info) info.headers = { ...info.headers, Authorization: `Bearer ${token}` };

            return originalFetch.current.apply(this, [input, info]);
        };
    }, [isAuthenticated]);

    useLayoutEffect(() => {
        if (!isAuthenticated) return;

        instance.acquireTokenSilent({ scopes: state.instance!.scopes }).then(parseToken);
    }, [isAuthenticated]);

    useEffect(() => {
        if (!state.instance)
            throw new Error("No BuildwiseApplication instance available, authentication will most likely not work.");
    }, [state.instance]);

    const parseToken = (result: AuthenticationResult) => {
        const idTokenClaims = result.idTokenClaims as IdTokenClaims;
        const wtcbAuth = JSON.parse(idTokenClaims.wtcb_authorization) as WtcbAuthorization;
        const isAdmin = wtcbAuth.roles.some((x) => x.bbri_code === 71300);

        dispatch(
            authenticate(
                { id: idTokenClaims.sub, name: idTokenClaims.display_name, email: idTokenClaims.email },
                isAdmin
            )
        );
        state.onLoginSuccess && state.onLoginSuccess();
    };

    const getAccessToken = async (): Promise<string | null | void> => {
        try {
            const token = await instance.acquireTokenSilent({
                scopes: state.instance!.scopes,
            });

            return token.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                if (state.useRedirect) return instance.acquireTokenRedirect({ scopes: state.instance!.scopes });
                else
                    return instance
                        .acquireTokenPopup({
                            scopes: state.instance!.scopes,
                            redirectUri: "",
                        })
                        .then((resp) => resp.accessToken)
                        .catch((err) => {
                            console.error("Authentication failed:", error, err);
                            return null;
                        });
            } else {
                console.error("Failed to obtain token:", error);
            }
        }
    };

    if (
        (isAuthenticated && inProgress !== InteractionStatus.Startup) ||
        (!isAuthenticated &&
            inProgress !== InteractionStatus.Startup &&
            inProgress !== InteractionStatus.HandleRedirect)
    )
        return <React.Fragment>{children}</React.Fragment>;

    return <Spinner />;
};

export default BuildwiseAuthenticationHandler;
