import React, { HTMLAttributes, isValidElement, cloneElement, ReactElement } from "react";
import { SelectGroupContext } from "./SelectGroupContext";
import SelectGroupItem, { SelectGroupItemProps } from "./SelectGroupItem";

export interface SelectGroupProps extends HTMLAttributes<HTMLDivElement> {
    /** One or more elements of `<SelectGroupItem>` */
    children: ReactElement<SelectGroupItemProps> | Array<ReactElement<SelectGroupItemProps>>;
    /** The identifier of the initially selected `<SelectGroupItem>` */
    selected: string;
    /** Callback function that is to be triggered when a `<SelectGroupItem>` is selected. */
    onSelectionChanged?: (identifier: string) => void;
}

const SelectGroup = ({ id, children, onSelectionChanged, selected, ...other }: SelectGroupProps) => {
    const onSelectGroupItemClicked = (identifier: string) => {
        onSelectionChanged && onSelectionChanged(identifier);
    };

    return (
        <SelectGroupContext.Provider value={{}}>
            <div {...other}>
                {React.Children.map(children, (child: ReactElement<SelectGroupItemProps>, index) => {
                    if (isValidElement(child) && child.type === SelectGroupItem)
                        return cloneElement(child, {
                            key: index,
                            selected: selected === child.props.identifier,
                            onClick: () => onSelectGroupItemClicked(child.props.identifier),
                        });

                    return null;
                })}
            </div>
        </SelectGroupContext.Provider>
    );
};

export default SelectGroup;
