import { ElementType, HTMLAttributes } from "react";
import { useSelectGroupContext } from "./SelectGroupContext";

export interface SelectGroupItemDescriptionProps extends HTMLAttributes<HTMLHeadingElement> {
    /** The component used for the root node. Either a string to use a HTML element or a component */
    component?: ElementType;
    /** Description of the item. */
    children: string;
}

const SelectGroupItemDescription = ({ component, children, ...other }: SelectGroupItemDescriptionProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = useSelectGroupContext();
    const Root: ElementType = component ?? "p";

    return <Root {...other}>{children}</Root>;
};

export default SelectGroupItemDescription;
