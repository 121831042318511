import { HTMLAttributes, ReactElement, ReactNode } from "react";
import DropdownButtonStartIcon from "./DropdownButtonStartIcon";
import { DropdownItemProps } from "./DropdownItem";
import { ChevronDown } from "react-feather";

import styles from "./DropdownButton.module.css";

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
    /** Variant of the button. */
    variant?: "primary" | "secondary" | "tertiary";
    /** If true, the button will use 100% of the width of its container. */
    block?: boolean;
    /** If true, mouse events are disabled and the button appears faded. */
    disabled?: boolean;
    /** An icon to render before the content of the button. */
    startIcon?: ReactNode;
    /** The text to appear on the button. */
    label: string;
    /** The dropdown options to appear when the button is hovered. */
    children: ReactElement<DropdownItemProps> | Array<ReactElement<DropdownItemProps>>;
}

const DropdownButton = ({
    variant,
    block,
    disabled,
    label,
    className,
    children,
    startIcon: startIconProp,
    style,
    ...other
}: ButtonProps) => {
    const classNames = [styles.button, styles[variant ?? "primary"]];
    const containerClassNames = [styles.container];

    if (disabled) classNames.push(styles.disabled);
    if (block) {
        classNames.push(styles.block);
        containerClassNames.push(styles.block);
    }
    if (className) classNames.push(className);

    const startIcon = startIconProp && <DropdownButtonStartIcon>{startIconProp}</DropdownButtonStartIcon>;

    return (
        <div className={containerClassNames.join(" ")} style={style}>
            <button className={classNames.join(" ")} {...other}>
                {startIcon}
                <span className={styles.text}>{label}</span>
                <span className={[styles.icon, styles.end].join(" ")}>
                    <ChevronDown />
                </span>
            </button>
            <div className={styles.dropdown}>
                <ul className={styles[variant ?? "primary"]}>{children}</ul>
            </div>
        </div>
    );
};

export default DropdownButton;
