import { HTMLAttributes } from "react";
import { useBoxContext } from "./Box";

import styles from "./Box.module.css";

export interface BoxTitleProps extends HTMLAttributes<HTMLHeadingElement> {
    /** The title of the Box component */
    children: string;
}

const BoxTitle = ({ className, children, ...other }: BoxTitleProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = useBoxContext();
    const activeStyles = [styles.title];
    if (className) activeStyles.push(className);

    return (
        <h3 className={activeStyles.join(" ")} {...other}>
            {children}
        </h3>
    );
};

export default BoxTitle;
