import { HTMLAttributes, ReactNode } from "react";
import { ChevronDown } from "react-feather";

import styles from "./Header.module.css";

interface ApplicationSubmenuItemProps extends HTMLAttributes<HTMLLIElement> {
    icon: ReactNode;
    label: string;
    children: ReactNode;
}

const ApplicationSubmenuItem = ({ icon, label, children, ...other }: ApplicationSubmenuItemProps) => {
    return (
        <li {...other}>
            <span style={{ pointerEvents: "none" }}>
                {icon}
                {label}
                {children && (
                    <ChevronDown style={{ marginLeft: "auto", marginRight: "0px" }} className={styles.chevron} />
                )}
            </span>
            {children && (
                <div className={styles.submenu}>
                    <div className={styles.dropdown}>
                        <ul>{children}</ul>
                    </div>
                </div>
            )}
        </li>
    );
};

export default ApplicationSubmenuItem;
