import { HTMLAttributes } from "react";
import { useQuestionBoxContext } from "./QuestionBoxContext";

import styles from "./QuestionBox.module.css";

export interface QuestionBoxImageProps extends HTMLAttributes<HTMLDivElement> {
    /** The address or URL of the a media resource that is to be considered. */
    src: string;
}

const QuestionBoxImage = ({ src, className, style, children, ...other }: QuestionBoxImageProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = useQuestionBoxContext();
    const activeStyles = [styles.image];
    if (className) activeStyles.push(className);

    // eslint-disable-next-line jsx-a11y/alt-text
    return (
        <div
            className={activeStyles.join(" ")}
            style={{ backgroundImage: "url(" + src + ")", ...style }}
            {...other}
        ></div>
    );
};

export default QuestionBoxImage;
