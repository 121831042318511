import { HTMLAttributes, useState, useEffect, Children, ReactNode, isValidElement } from "react";

import styles from "./QuestionBox.module.css";
import QuestionBoxBody from "./QuestionBoxBody";
import { QuestionBoxContext } from "./QuestionBoxContext";
import QuestionBoxImage from "./QuestionBoxImage";
import QuestionBoxInfo from "./QuestionBoxInfo";
import QuestionBoxTitle from "./QuestionBoxTitle";

export interface QuestionBoxProps extends HTMLAttributes<HTMLDivElement> {
    /** Unique combination of `<QuestionBoxTitle>`, `<QuestionBoxBody>`, `<QuestionBoxInfo>` and `<QuestionBoxImage>` */
    children: ReactNode;
}

interface QuestionItems {
    title?: ReactNode;
    body?: ReactNode;
    info?: ReactNode;
    image?: ReactNode;
}

const QuestionBox = ({ className, children, ...other }: QuestionBoxProps) => {
    const [data, setData] = useState<QuestionItems>();
    const activeStyles = [styles.question];
    if (className) activeStyles.push(className);

    useEffect(() => {
        const items: QuestionItems = {};
        Children.forEach(children, (child) => {
            if (isValidElement(child)) {
                switch (child.type) {
                    case QuestionBoxTitle:
                        items.title = child;
                        break;

                    case QuestionBoxBody:
                        items.body = child;
                        break;

                    case QuestionBoxInfo:
                        items.info = child;
                        break;

                    case QuestionBoxImage:
                        items.image = child;
                        break;
                }
            }
        });

        setData(items);
    }, [children]);

    return (
        <QuestionBoxContext.Provider value={{}}>
            <div className={activeStyles.join(" ")} {...other}>
                <div>
                    {data?.title}
                    {data?.body}
                    {data?.info}
                </div>
                {data?.image}
            </div>
        </QuestionBoxContext.Provider>
    );
};

export default QuestionBox;
