import { useContext, createContext } from "react";

export const SelectGroupContext = createContext({});

export const useSelectGroupContext = () => {
    const context = useContext(SelectGroupContext);
    if (!context)
        throw new Error(`SelectGroup compound components cannot be rendered outside the SelectGroup component.`);

    return context;
};
