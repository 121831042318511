import { createContext, useContext } from "react";

export const StepperContext = createContext({});

export const useStepperContext = () => {
    const context = useContext(StepperContext);
    if (!context)
        throw new Error(`Stepper compound components cannot be rendered outside the Stepper component.`);

    return context;
};
