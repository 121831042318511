import { Configuration } from "@azure/msal-browser";
import { BuildwiseApplicationConfiguration, PrismicConfiguration } from "./BuildwiseApplicationConfiguration";

export class BuildwiseApplication {
    private _configuration: BuildwiseApplicationConfiguration;

    /**
     * @constructor
     * Constructor for the BuildwiseApplication used to instantiate the BuildwiseApplication object.
     *
     * @param configuration object for the Buildwise Application instance
     */
    constructor(configuration: BuildwiseApplicationConfiguration) {
        this._configuration = configuration;
    }

    public get msalConfiguration(): Configuration {
        return {
            auth: {
                clientId: this._configuration.authentication.clientId,
                authority: this._configuration.authentication.authority,
                knownAuthorities: this._configuration.authentication.knownAuthorities,
                redirectUri: this._configuration.authentication.redirectUri,
                postLogoutRedirectUri: this._configuration.authentication.postLogoutRedirectUri,
                navigateToLoginRequestUrl: false,
            },
            cache: {
                cacheLocation: this._configuration.authentication.cacheLocation,
            },
            // system: {
            //     loggerOptions: {
            //         loggerCallback: (logLevel, message, containsPii) => console.log(message),
            //         logLevel: LogLevel.Verbose,
            //         piiLoggingEnabled: true,
            //     },
            // },
        };
    }

    public get scopes(): Array<string> {
        return this._configuration.authentication.scopes;
    }

    public get authority(): string {
        return this._configuration.authentication.authority;
    }

    public get feedbackEndpoint(): string {
        return this._configuration.feedbackEndpoint;
    }

    public get prismic(): PrismicConfiguration {
        return this._configuration.prismic;
    }
}
