import { useContext, useEffect, useRef, useState } from "react";
import { MessageSquare, MoreHorizontal, UploadCloud } from "react-feather";
import { PrismicRichText } from "@prismicio/react";

import { BuildwiseContext } from "../../core/BuildwiseProvider/BuildwiseContext";
import { setFeedbackFormVisibility } from "../../core/BuildwiseProvider/BuildwiseReducer";

import useAuthentication from "../../../hooks/useAuthentication/useAuthentication";
import usePrismic from "../../../hooks/usePrismic/usePrismic";

import Modal from "../../feedback/Modal/Modal";
import ModalBody from "../../feedback/Modal/ModalBody";
import ModalFooter from "../../feedback/Modal/ModalFooter";
import ModalHeader from "../../feedback/Modal/ModalHeader";
import ModalTitle from "../../feedback/Modal/ModalTitle";
import Button from "../../inputs/Button/Button";
import Radio from "../../inputs/Radio/Radio";
import Text from "../../inputs/Text/Text";

import styles from "./FeedbackForm.module.css";

const FeedbackForm = () => {
    const { state, dispatch } = useContext(BuildwiseContext);
    const [open, setOpen] = useState(false);
    const { isAuthenticated, user } = useAuthentication();
    const [document, { state: prismicState }] = usePrismic("bbriui");

    const [feedback, setFeedback] = useState("");
    const [recommend, setRecommend] = useState(-1);
    const [screenshot, setScreenshot] = useState<File | undefined>();
    const [sent, setSent] = useState(false);
    const [sending, setSending] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const feedbackEndpoint = state.instance?.feedbackEndpoint;

    useEffect(() => {
        setOpen(state.feedbackForm);
        if (state.feedbackForm) setSent(false);
    }, [state.feedbackForm]);

    const toggleFeedback = () => {
        dispatch(setFeedbackFormVisibility(!open));
    };

    const sendFeedback = () => {
        if (!feedbackEndpoint) return;

        setSending(true);

        let userId = "Demo user";
        let email = "";

        if (isAuthenticated && user) {
            userId = user.id;
            email = user.email;
        }

        const formData = new FormData();
        formData.append("Authentication.Bbri_userid", userId);
        formData.append("Case.Bbri_current_page", window.location.toString());
        formData.append("Contact.Bbri_contact_email", email);
        formData.append("Case.Bbri_case_description", feedback);
        formData.append("Case.Bbri_case_extra", recommend.toString());

        if (screenshot) formData.append("Screenshot", screenshot);

        const options = {
            headers: {
                Accept: "application/json",
            },
            method: "POST",
            body: formData,
        };

        fetch(feedbackEndpoint, options)
            .then((_) => setSent(true))
            .catch((e) => console.warn("Failed to send feedback:", e));
    };

    const renderScreenshot = () => {
        if (screenshot) {
            return (
                <span>
                    {document!.data.feedback_form_screenshot_selection}: <strong>{screenshot.name}</strong>
                </span>
            );
        }

        return <span>{document!.data.feedback_form_screenshot_no_selection}</span>;
    };

    const activeStyles = [styles.button];
    if (open) activeStyles.push(styles.active);

    return prismicState === "loaded" ? (
        <>
            <input
                type="file"
                id="feedback-attachment"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={(e) => setScreenshot(e.target.files?.[0])}
                accept="image/*"
            />

            <div className={activeStyles.join(" ")} onClick={() => toggleFeedback()}>
                <div className={styles.icons}>
                    <MessageSquare className={styles.base} />
                    <MoreHorizontal className={styles.dots} />
                </div>
            </div>

            <Modal open={open} width={400} className={styles.modal} onClose={() => toggleFeedback()}>
                <ModalHeader closeButton>
                    <ModalTitle>
                        {document!.data[sent ? "feedback_form_title_thanks" : "feedback_form_title"]}
                    </ModalTitle>
                </ModalHeader>
                {feedbackEndpoint ? (
                    sent ? (
                        <ModalBody className={styles.content}>
                            <PrismicRichText
                                field={document!.data.feedback_form_info_received}
                                linkResolver={() => "/"}
                            />
                        </ModalBody>
                    ) : (
                        <>
                            <ModalBody className={styles.content}>
                                <div style={{ marginBottom: "32px" }}>
                                    <PrismicRichText
                                        field={document!.data.feedback_form_info_description}
                                        linkResolver={() => "/"}
                                    />
                                </div>

                                <div className={styles.input}>
                                    <label>{document!.data.feedback_form_input_comments_label}</label>
                                    <Text
                                        type="textarea"
                                        placeholder={document!.data.feedback_form_input_comments_placeholder}
                                        value={feedback}
                                        onChange={(e) => setFeedback(e.currentTarget.value)}
                                        required={true}
                                    />
                                </div>

                                <div className={styles.input}>
                                    <Button
                                        id="feedback-form-add-attachment-button"
                                        block
                                        startIcon={<UploadCloud />}
                                        onClick={() => fileInputRef.current?.click()}
                                        style={{ marginBottom: 10 }}
                                    >
                                        {document!.data.feedback_form_input_screenshot_label}
                                    </Button>
                                    {renderScreenshot()}
                                </div>

                                <div className={styles.input}>
                                    <label>{document!.data.feedback_form_input_recommendation_label}</label>
                                    <div className={styles.radio}>
                                        <Radio
                                            label={document!.data.feedback_form_input_recommendation_option_yes}
                                            name={"recommendation"}
                                            checked={recommend === 2}
                                            onChange={() => setRecommend(2)}
                                        />
                                        <Radio
                                            label={document!.data.feedback_form_input_recommendation_option_maybe}
                                            name={"recommendation"}
                                            checked={recommend === 1}
                                            onChange={() => setRecommend(1)}
                                        />
                                        <Radio
                                            label={document!.data.feedback_form_input_recommendation_option_no}
                                            name={"recommendation"}
                                            checked={recommend === 0}
                                            onChange={() => setRecommend(0)}
                                        />
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter className={styles.footer}>
                                <Button
                                    id="feedback-form-cancel-button"
                                    variant="tertiary"
                                    style={{ marginRight: "10px" }}
                                    onClick={() => toggleFeedback()}
                                >
                                    {document!.data.feedback_form_button_cancel_text}
                                </Button>

                                <Button
                                    id="feedback-form-submit-button"
                                    onClick={() => sendFeedback()}
                                    disabled={feedback.trim() === "" || sending}
                                >
                                    {sending
                                        ? document!.data.feedback_form_button_send_progress_text
                                        : document!.data.feedback_form_button_send_text}
                                </Button>
                            </ModalFooter>
                        </>
                    )
                ) : (
                    <ModalBody className={styles.content}>Feedback endpoint not configured.</ModalBody>
                )}
            </Modal>
        </>
    ) : null;
};

export default FeedbackForm;
