import {
    Children,
    HTMLAttributes,
    ReactElement,
    useEffect,
    useState,
    isValidElement,
    cloneElement,
    ReactNode,
} from "react";
import Step, { StepProps } from "./Step";

import styles from "./Stepper.module.css";
import { StepperContext } from "./StepperContext";

export interface StepperProps extends HTMLAttributes<HTMLDivElement> {
    /** The current active step. */
    step: number;
    /** Callback function that is triggered when the active step changes. This should return true or false to indicate if the user is allowed to go to the next step or not. */
    onChangeStep: (currentStep: number, targetStep: number) => boolean;
    /** The different steps and their content. */
    children: ReactElement<StepProps> | Array<ReactElement<StepProps>>;
}

const Stepper = ({ step, onChangeStep, children, className, ...other }: StepperProps) => {
    const [active, setActive] = useState<number>(step);
    const [steps, setSteps] = useState<ReactNode[]>();
    const activeStyles = [styles.container];
    if (className) activeStyles.push(className);

    useEffect(() => {
        setActive(step);
    }, [step]);

    useEffect(() => {
        const steps: Array<ReactNode> = [];
        Children.forEach(children, (child, index) => {
            if (isValidElement(child) && child.type === Step) {
                steps.push(
                    cloneElement(child, {
                        key: index,
                        step: index,
                        selected: index === active,
                        completed: index < active,
                        onSelectStep: () => trySetActive(index),
                    })
                );
            }
        });

        setSteps(steps);
    }, [children, active]);

    const trySetActive = (index: number) => {
        if (onChangeStep && onChangeStep(active, index) === false) return;
        setActive(index);
    };

    const renderActiveStep = () => {
        if (!steps) return;
        return (steps[active] as any)?.props.children;
    };

    return (
        <StepperContext.Provider value={{}}>
            <div className={activeStyles.join(" ")} {...other}>
                <ul className={styles.box}>{steps}</ul>
            </div>
            {renderActiveStep()}
        </StepperContext.Provider>
    );
};

export default Stepper;
