import { HTMLAttributes, ReactNode } from "react";
import { useContentContext } from "./Content";

import styles from "./Content.module.css";

export interface ContentActionsProps extends HTMLAttributes<HTMLDivElement> {
    /** Elements displayed as content actions */
    children: ReactNode;
}

const ContentActions = ({ children, className, ...other }: ContentActionsProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = useContentContext();
    const activeStyles = [styles.actions];
    if (className) activeStyles.push(className);

    return (
        <div className={activeStyles.join(" ")} {...other}>
            {children}
        </div>
    );
};

export default ContentActions;
