import { ReactNode } from "react";

export interface DropdownItemProps {
    /** The content to appear on the dropdown button item. */
    children: ReactNode;
}

const DropdownItem = ({ children }: DropdownItemProps) => {
    return <li>{children}</li>;
};

export default DropdownItem;
