import { HTMLAttributes, ReactNode } from "react";
import { useQuestionBoxContext } from "./QuestionBoxContext";

import styles from "./QuestionBox.module.css";

export interface QuestionBoxBodyProps extends HTMLAttributes<HTMLDivElement> {
    /** Body of the question box. */
    children: ReactNode;
}

const QuestionBoxBody = ({ className, children, ...other }: QuestionBoxBodyProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = useQuestionBoxContext();
    const activeStyles = [styles.body];
    if (className) activeStyles.push(className);

    return (
        <div className={activeStyles.join(" ")} {...other}>
            {children}
        </div>
    );
};

export default QuestionBoxBody;
