import { HTMLAttributes, ReactNode } from "react";
import { useSelectGroupContext } from "./SelectGroupContext";

export interface SelectGroupItemImageProps extends HTMLAttributes<HTMLImageElement> {
    /** The address or URL of the a media resource that is to be considered. */
    src?: string;
    /** An element to render instead of using an image with a media resource. */
    render?: ReactNode;
}

const SelectGroupItemImage = ({ render, src, ...other }: SelectGroupItemImageProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = useSelectGroupContext();

    // eslint-disable-next-line jsx-a11y/alt-text
    return Boolean(render) ? render : <img src={src} {...other} />;
};

export default SelectGroupItemImage;
