import { HTMLAttributes, ReactNode } from "react";
import { useBoxContext } from "./Box";

import styles from "./Box.module.css";

export interface BoxBodyProps extends HTMLAttributes<HTMLDivElement> {
    /** The body of the Box component */
    children: ReactNode;
}

const BoxBody = ({ className, children, ...other }: BoxBodyProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = useBoxContext();
    const activeStyles = [styles.body];
    if (className) activeStyles.push(className);

    return (
        <div className={activeStyles.join(" ")} {...other}>
            {children}
        </div>
    );
};

export default BoxBody;
