import { ElementType, HTMLAttributes } from "react";
import { useQuestionBoxContext } from "./QuestionBoxContext";

import styles from "./QuestionBox.module.css";

export interface QuestionBoxTitleProps extends HTMLAttributes<HTMLParagraphElement> {
    /** The component used for the root node. Either a string to use a HTML element or a component */
    component?: ElementType;
    /** Title of the question box. */
    children: string;
}

const QuestionBoxTitle = ({ component, className, children, ...other }: QuestionBoxTitleProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = useQuestionBoxContext();
    const activeStyles = [styles.title];
    if (className) activeStyles.push(className);
    const Root: ElementType = component ?? "p";

    return (
        <Root className={activeStyles.join(" ")} {...other}>
            {children}
        </Root>
    );
};

export default QuestionBoxTitle;
