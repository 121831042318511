import { ElementType, HTMLAttributes } from "react";
import { useSelectGroupContext } from "./SelectGroupContext";

export interface SelectGroupItemTitleProps extends HTMLAttributes<HTMLHeadingElement> {
    /** The component used for the root node. Either a string to use a HTML element or a component */
    component?: ElementType;
    /** Title of the item. */
    children: string;
}

const SelectGroupItemTitle = ({ component, children, ...other }: SelectGroupItemTitleProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = useSelectGroupContext();
    const Root: ElementType = component ?? "h3";

    return <Root {...other}>{children}</Root>;
};

export default SelectGroupItemTitle;
